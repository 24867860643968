import {pick} from "lodash";
import {PlainDate, TIMEUNIT_DAYS} from "@kiss-solutions/plaindate";

export const userKeys = {
    all: ['users'],
    list: () => [...userKeys.all, 'list'],
    details: () => [...userKeys.all, 'detail'],
    base: (id) => [...userKeys.details(), id],
    locations: (id) => [...userKeys.details(), id, 'locations'],
    artists: (id) => [...userKeys.details(), id, 'artists'],
    usages: (id) => [...userKeys.details(), id, 'usages'],
    invoices: (id) => [...userKeys.details(), id, 'invoices'],
    singleUsage: (userId, usageId) => [...userKeys.usages(userId), usageId],
    singleInvoice: (userId, invoiceId) => [...userKeys.invoices(userId), invoiceId]
}

export const userConversionKeys = {
    all: ['user-conversions'],
    list: () => [...userConversionKeys.all, 'list']
}
const activeUserFilter = (user) => {
    const testBisPd = new PlainDate(user.TestBis)
    const today = new PlainDate()
    return testBisPd.getDiff(today, TIMEUNIT_DAYS) < 10 || user.AboStart != null && user.AboEnde != null
}
export const userDataSelector = data => data.filter(user => activeUserFilter(user))
    .map(d => pick(d, ['UserID', 'studio_name', 'Seit', 'TestBis', 'AboStart', 'AboEnde', 'abrechBis', 'gezBis', 'Kulanz', 'SignPadCount', 'AccBalance', 'AccBills', 'last_contact']))


