import React from 'react';
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "./queries";
import { intersection } from "lodash/array";

const isAuthorized = (auth, allowedRoles) => {
    if (!auth?.member?.roles) {
        return false;
    }
    const roles = auth.member.roles.map(r => r.role);
    return intersection(roles, allowedRoles).length > 0;
};

const AuthGuard = ({ allowedRoles }) => {
    const location = useLocation();
    const { auth, isAuthenticated } = useAuth();

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return isAuthorized(auth, allowedRoles) ? (
        <Outlet />
    ) : (
        <Navigate to="/unauthorized" state={{ from: location }} replace />
    );
};

export default AuthGuard;
