import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
    setAuth,
    setAuthError,
    useStateContext,
} from "../../context/StateProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosPublic } from "../../api/axiosPublic";
import { useEffect, useRef } from "react";
import { parseJwt } from "../../utils/jwt-decode";
import { warehouseKeys } from "../../warehouse/warehouseQueries";
import { userKeys } from "../../users/userQueries";
import { studioKeys } from "../../studios/studioQueries";

export const authKeys = {
    all: ["auth"],
};

const checkAuth = () => {
    const token = localStorage.getItem("token");
    if (token) {
        return {
            token,
            member: parseJwt(token),
        };
    }
    return null;
};

export const useAuth = () => {
    const stateContext = useStateContext();
    const navigate = useNavigate();
    const authRef = useRef(checkAuth());
    const initializedRef = useRef(false);

    useEffect(() => {
        if (!initializedRef.current) {
            initializedRef.current = true;
            if (authRef.current) {
                stateContext.dispatch(setAuth(authRef.current));
            } else {
                navigate("/login");
            }
        }
    }, [stateContext, navigate]);

    return {
        auth: authRef.current,
        isAuthenticated: !!authRef.current
    };
};

const loginUserFn = async (loginData) => {
    const response = await axiosPublic.post("/api/v1/members/login", loginData);
    return response.data;
};

const logoutUserFn = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
};

export const useLogin = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const stateContext = useStateContext();
    
    const { mutate: loginUser, isLoading } = useMutation({
        mutationFn: loginUserFn,
        onError: (err) => stateContext.dispatch(setAuthError(err.response?.status)),
        onSuccess: (data) => {
            const { token, refreshToken } = data;
            localStorage.setItem("token", token);
            localStorage.setItem("refreshToken", refreshToken);
            const auth = checkAuth();
            stateContext.dispatch(setAuth(auth));
            navigate(from);
        },
    });
    
    return { loginUser, isLoading };
};

export const useLogout = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { mutate: logout } = useMutation({
        mutationFn: () => logoutUserFn(),
        onSuccess: () => {
            navigate("/login");
            queryClient.refetchQueries(authKeys.all).then(() => {
                queryClient.removeQueries(warehouseKeys.all);
                queryClient.removeQueries(userKeys.all);
                queryClient.removeQueries(studioKeys.all);
            });
        },
    });
    return { logout };
};

export const removeTokens = () => {
    window.location.href = "/login";
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
};
