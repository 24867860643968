export const formatDate = (date, locale, timezone) => {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: timezone
    }
    return new Intl.DateTimeFormat(locale, options).format(date)
}

export const formatDateTime = (date, locale, timezone) => {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone
    }
    return new Intl.DateTimeFormat(locale, options).format(date)
}

export const formatTime = (date, locale, timezone) => {
    const options = {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone
    }
    return new Intl.DateTimeFormat(locale, options).format(date)
}

function padNumber(number) {
    return number.toString().padStart(2, '0')
}

export const formatDate2DB = (date) => `${date.getFullYear()}-${padNumber(date.getMonth() + 1)}-${padNumber(date.getDate())}`

export const dateValueGetter = (value) => value && new Date(value)
export const datetimeValueGetter = (value, locale, timezone) => value && formatDateTime(new Date(value), locale, timezone)


export const isNotOlderThan24Hrs = (date) => new Date().getTime() - new Date(date).getTime() < 24 * 3_600_000
