import React from 'react';

const INITIAL_STATE = {
  authUser: null,
  themeMode: 'light'
};

const StateContext = React.createContext(undefined)

export const SET_AUTH = 'SET_AUTH'
export const SET_LAST_CALL_ID = 'SET_LAST_CALL_ID'
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR'
export const SET_THEME_MODE = 'SET_THEME_MODE'
export const ADD_MESSAGE = 'ADD_MESSAGE'
const stateReducer = (state, action) => {
  switch (action.type) {
    case SET_AUTH: {
      return {
        ...state,
        auth: action.payload,
      };
    }
    case SET_LAST_CALL_ID: {
      return {
        ...state,
        lastInboundCallId: action.payload,
      };
    }
    case ADD_MESSAGE: {
      return {
        ...state,
        lastMessage: action.payload,
      };
    }
    case SET_AUTH_ERROR: {
      return {
        ...state,
        authError: action.payload,
      };
    }
    case SET_THEME_MODE: {
      return {
        ...state,
        themeMode: action.payload,
      };
    }

    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const StateContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(stateReducer, INITIAL_STATE);
  const value = { state, dispatch };
  return (
    <StateContext.Provider value={value}>{children}</StateContext.Provider>
  );
};

export const setThemeMode = (mode) => ({type: SET_THEME_MODE, payload: mode})
export const addMessage = (message) => ({type: ADD_MESSAGE, payload: message})
export const setAuth = (auth) => ({type: SET_AUTH, payload: auth})
export const setAuthError = (authError) => ({type: SET_AUTH, payload: authError})
export const selectThemeMode = (state) => state.themeMode
export const selectLocale = (state) => state?.auth?.member?.locale || 'en'
export const selectLastMessage = (state) => state.lastMessage
export const selectLastInboundCallId = (state) => state.lastInboundCallId
export const setLastInboundCallId = (lastInboundCallId) => ({type: SET_LAST_CALL_ID, payload: lastInboundCallId})
export const selectRoles = (state) => state.auth?.member?.roles
export const hasRole = (state, role) => state.auth?.member?.roles && state.auth.member.roles.find(r => r.role === role) !== undefined
export const selectMember = (state) => state.auth?.member
const useStateContext = () => {
  const context = React.useContext(StateContext);

  if (context) {
    return context;
  }

  throw new Error(`useStateContext must be used within a StateContextProvider`);
};

export { StateContextProvider, useStateContext };
