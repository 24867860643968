export const SCROLLBAR_STYLES = {
    "&::-webkit-scrollbar": {
        width: 5
    },
    "&::-webkit-scrollbar-track": (theme) => ({
        background: theme.palette.action.selected
    }),
    "&::-webkit-scrollbar-thumb": (theme) => ({
        background: theme.palette.text.disabled
    }),
    "&::-webkit-scrollbar-thumb:hover": (theme) => ({
        background: theme.palette.text.primary
    })
}

export const DATAGRID_SCROLLBAR_STYLES = {
   "& ::-webkit-scrollbar": {
    width: "6px",
    height: "6px"
  },
  "& ::-webkit-scrollbar-track": (theme) => ({
    background: theme.palette.action.selected
  }),
  "& ::-webkit-scrollbar-thumb": (theme) => ({
    background: theme.palette.mode === "dark" ? "#888" : "#ccc"
  })
}
