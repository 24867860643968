import {selectLastInboundCallId, selectMember, useStateContext} from "../context/StateProvider";
import {useMemo} from "react";
import {useQuery} from '@tanstack/react-query';
import {getLastInboundCall} from "../inbound-calls/inboundCallApi";
import {inboundCallKeys} from "../inbound-calls/inboundCallQueries";

const useLastInboundCall = () => {
    const {state} = useStateContext()
    const member = useMemo(() => selectMember(state), [state])
    const lastInboundCallId = useMemo(() => selectLastInboundCallId(state), [state])
    const query = useQuery({
        queryKey: inboundCallKeys.last(),
        queryFn: () => member != null ? getLastInboundCall(lastInboundCallId) : null,
    })
    return query.data
}

export default useLastInboundCall
