import React from "react";
import {dateValueGetter} from "../utils/dateHelper";
import {convertMap2TranslationMap} from "../utils/mapTranslations";
import * as yup from "yup";
import CheckIcon from "@mui/icons-material/Check";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import FlagIcon from '@mui/icons-material/Flag';
import {Image} from "@mui/icons-material";
import SpatialAudioIcon from '@mui/icons-material/SpatialAudio';
import VideocamIcon from '@mui/icons-material/Videocam';

export const COMM_TYPE_PHONE = 2
export const COMM_TYPE_EMAIL = 1
export const COMM_TYPE_SMS = 3
export const COMM_TYPE_WA = 4
export const COMM_TYPE_INSTA = 5
const COM_TYPE_MAP = {
    COMM_TYPE_PHONE,
    COMM_TYPE_EMAIL,
    COMM_TYPE_SMS,
    COMM_TYPE_WA,
    COMM_TYPE_INSTA
}
const BROADCAST_COM_TYPE_MAP = {
    COMM_TYPE_EMAIL,
    COMM_TYPE_SMS
}
const COM_TYPE_TRANSLATION_MAP = convertMap2TranslationMap(COM_TYPE_MAP)
const BROADCAST_COM_TYPE_TRANSLATION_MAP = convertMap2TranslationMap(BROADCAST_COM_TYPE_MAP)
export const getComTypeSelections = (t) => Object.entries(COM_TYPE_TRANSLATION_MAP).map(([key, value]) => ({
    id: key,
    text: t(value)
}))
export const getBroadcastComTypeSelections = (t) => Object.entries(BROADCAST_COM_TYPE_TRANSLATION_MAP).map(([key, value]) => ({
    id: key,
    text: t(value)
}))
export const COMM_SUCCESS_TYPE_NO_PICKUP = 1
export const COMM_SUCCESS_TYPE_POSTPONED = 2
export const COMM_SUCCESS_TYPE_BLOCK_CONTACT = 3
export const COMM_SUCCESS_TYPE_NO_INTEREST = 4
export const COMM_SUCCESS_TYPE_INTEREST_BUT_NOT_NOW = 5
export const COMM_SUCCESS_TYPE_INTEREST_SUCCESS = 6
export const COMM_SUCCESS_TYPE_MAP = {
    COMM_SUCCESS_TYPE_NO_PICKUP,
    COMM_SUCCESS_TYPE_POSTPONED,
    COMM_SUCCESS_TYPE_BLOCK_CONTACT,
    COMM_SUCCESS_TYPE_NO_INTEREST,
    COMM_SUCCESS_TYPE_INTEREST_BUT_NOT_NOW,
    COMM_SUCCESS_TYPE_INTEREST_SUCCESS,
}
export const COMM_SUCCESS_TYPE_TRANSLATION_MAP = convertMap2TranslationMap(COMM_SUCCESS_TYPE_MAP)
export const getSuccessTypeSelections = (t) => Object.entries(COMM_SUCCESS_TYPE_TRANSLATION_MAP).map(([key, value]) => ({
    id: key,
    text: t(value)
}))

const COMM_STATE_IN_PROGRESS = 1
const COMM_STATE_CLOSED = 2
const COMM_STATE_MAP = {
    COMM_STATE_IN_PROGRESS,
    COMM_STATE_CLOSED
}
const COMM_STATE_TRANSLATION_MAP = convertMap2TranslationMap(COMM_STATE_MAP)
export const getCommunicationStateSelections = (t) => Object.entries(COMM_STATE_TRANSLATION_MAP).map(([key, value]) => ({
    id: key,
    text: t(value)
}))

export const getCommunicationFields = (t, membersMap) => [
    {field: "date", type: 'dateTime', headerName: t('DATE_LABEL'), valueGetter: dateValueGetter, minWidth: 150},
    {field: "studio_name", type: "number", headerName: t('STUDIO_NAME_LABEL')},
    {field: "incoming", type: "boolean", headerName: t('COMMUNICATION_INCOMING_LABEL')},
    {
        field: 'media_mime_type', headerName: t('COMMUNICATION_MIME_TYPE_LABEL'), renderCell: (params) => {
            if (!params.row.incoming) {
                return null
            }
            const mimeType = params.value;

            if (!mimeType) {
                return null
            } else if (mimeType.startsWith('image')) {
                return <Image color="primary" />;
            } else if (mimeType.startsWith('audio')) {
                return <SpatialAudioIcon color="primary" />;
            } else if (mimeType.startsWith('video')) {
                return <VideocamIcon color="primary" />;
            } else {
                return null;
            }
        }
    },
    {
        field: 'state', headerName: t('COMMUNICATION_STATE_LABEL'), renderCell: (params) => {
            if (!params.row.incoming) {
                return null
            }
            const status = params.value;

            if (status === COMM_STATE_IN_PROGRESS) {
                return <FlagIcon color="warning" />;
            } else if (status === COMM_STATE_CLOSED) {
                return <CheckIcon color="success" />;
            } else {
                return <QuestionMarkIcon color="error" />;
            }
        }
    },
    {
        field: "success_code",
        headerName: t('COMMUNICATION_SUCCESS_CODE_LABEL'),
        valueGetter: (value) => value && t(COMM_SUCCESS_TYPE_TRANSLATION_MAP[value])
    },
    {field: "first_name", headerName: t('FIRST_NAME_LABEL')},
    {field: "last_name", headerName: t('LAST_NAME_LABEL')},
    {field: "type", headerName: t('TYPE_LABEL'), valueGetter: (value) => t(COM_TYPE_TRANSLATION_MAP[value])},
    {field: "subject", headerName: t('SUBJECT_LABEL'), minWidth: 150},
    {field: "message", headerName: t('MESSAGE_LABEL'), minWidth: 150},
    {field: "receiver", headerName: t('RECEIVER_LABEL'), minWidth: 150},
    {
        field: "member_id",
        headerName: t('COMMUNICATION_LIST_MEMBER_ID_LABEL'),
        valueGetter: (value) => value && membersMap && membersMap[value].kuerzel
    },
    {field: "id", type: "number", headerName: t('STUDIO_ID_LABEL')},
    {field: "studio_id", type: "number", headerName: t('STUDIO_ID_LABEL')},
    {field: "studio_contact_id", type: "number", headerName: t('STUDIO_CONTACT_ID_LABEL')},
    {field: "external_status", headerName: t('EXTERNAL_STATUS_LABEL'), minWidth: 250},
]

export const createCommunicationsSchema = () => {
    return yup.object().shape({
        date: yup.date().required(),
        subject: yup.string().max(200).nullable(),
        receiver: yup.string().max(200).nullable(),
        message: yup.string().max(1000).nullable(),
    })
}

export const convertCommunications2Schema = (communications) => communications && ({
    ...communications,
    state: communications.state || '',
    studio_contact_id: communications.studio_contact_id || '',
    member_id: communications.member_id || '',
    success_code: communications.success_code || '',
    date: new Date(communications.date)
})

export const getNewCommunication = ({
                                        studioId,
                                        studioContactId = '',
                                        successCode = '',
                                        receiver = '',
                                        subject = 'new',
                                        memberId
                                    }) => ({
    studio_id: studioId,
    type: COMM_TYPE_PHONE,
    studio_contact_id: studioContactId,
    date: new Date(),
    subject,
    message: '',
    success_code: successCode,
    receiver,
    member_id: memberId
})

